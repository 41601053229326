import React from 'react'
import Layout from '../components/layout'
import PageTitle from '../components/pageTitle'
import ContactForm from '../components/contactForm'
import Seo from '../components/seo'

export default function Contact() {
  return (
    <Layout>
      <Seo 
          title={'Contact Us For Product Inquiries | Gazelle® Tools'}
          description='Contact Gazelle® for product related inquiries, distributor details, or support. Reach out today for expert assistance.'
      />
      
      <PageTitle
        title="Contact Us"
        subtitle="We look forward to servicing your job site needs. Please fill out the form below and we will get in touch with you shortly."
      />
      <div className="grid grid-cols-1 lg:grid-cols-2 px-4 lg:px-16 py-6 md:gap-x-16 2xl:gap-x-20">
        <div className="py-3 md:py-0 mt-2 md:mt-0">
          <ContactForm title="Send Us A Message" />
        </div>
        <div className='pb-4 border-b border-secondary md:border-none order-first md:order-last'>
          <h3 className="leading-none text-lg md:text-xl 2xl:text-2xl text-primary">Product Inquiries</h3>
          <a href="mailto:sales@gazelleindustrial.com" className="text-xs md:text-base 2xl:text-lg">sales@gazelleindustrial.com</a>
          <h3 className="leading-none text-lg md:text-xl 2xl:text-2xl text-primary mt-4 md:mt-8">Customer Care</h3>
          <a href="mailto:support@gazelleindustrial.com" className="text-xs md:text-base 2xl:text-lg">support@gazelleindustrial.com</a>
        </div>
      </div>
    </Layout>
  )
}
